/* CSS for animated bouncing loader. */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100vh;
  background-image: linear-gradient(
    0deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
}

.loader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

/* Loader circles */
.loader > span,
.loader2 > span {
  background: #ffffff;
  border-radius: 50%;
  margin: 5rem 0.5rem;
}

.loader > span {
  background: #ffffff;
  border-radius: 50%;
  margin: 5rem 0.5rem;
  animation: bouncingLoader 0.8s infinite alternate;
}

.loader2 > span {
  margin: 0;
  animation: fadingLoader 0.8s infinite alternate;
}

.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}

.pre-loader {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999999;
  text-align: center;
  transform: translateY(0%);
}

.pre-load-pos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pre-loader img {
  width: 100px;
  height: 100px;
  font-size: 4em;
  color: #cb1c1e;
  animation: preLoader 3s infinite;
}

@keyframes preLoader {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 0.7;
    transform: scale(0.4);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  70% {
    opacity: 0.7;
    transform: scale(0.4);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes lazyLoad {
  0% {
    background: linear-gradient(
      90deg,
      rgba(214, 214, 214, 0) 0%,
      #ae151b 0%,
      rgba(214, 214, 214, 0.00043767507002800965) 51%
    );
  }

  50% {
    background: linear-gradient(
      90deg,
      rgba(214, 214, 214, 0) 0%,
      #ae151b 50%,
      rgba(214, 214, 214, 0.00043767507002800965) 100%
    );
  }

  100% {
    background: linear-gradient(
      90deg,
      rgba(214, 214, 214, 0) 0%,
      #ae151b 100%,
      rgba(214, 214, 214, 0.00043767507002800965) 100%
    );
  }
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
@keyframes fadingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: scale(-1em);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: scale(1em);
  }
}
