body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.has-vivid-red-color{
  color:#cf2e2e
}

.has-small-font-size {
  font-size: small;
}

.has-medium-font-size {
  font-size: medium;
}

.has-large-font-size {
  font-size:large;
}

.has-x-large-font-size {
  font-size:x-large;
}

.MuiListItem-button{
  text-align: center !important;
}

.MuiListItem-button span{
  font-weight: bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
