.btn--defualt {
  background-color: #ed1c24;
  border-radius: 20px;
  padding: 10px 50px;
  margin: 0;
  margin-bottom: 20px;
  display: block;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
}

.btn--defualt:hover {
  background-color: #79797a;
  color: #fff;
  cursor: pointer;
}

.btn--gradient {
  background-color: rgba(218, 10, 10, 0.85);
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  border: none;
  border-style: none;
  border-radius: 40px;
  /* padding: 15px 150px; */
  margin: auto;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
  display: block;
  box-sizing: border-box;
}

.btn--gradient:hover {
  background-color: rgba(255, 255, 255, 0.85);
  background: linear-gradient(
    90deg,
    rgb(195, 11, 183) 0%,
    rgba(237, 28, 36, 1) 100%
  );
  filter: drop-shadow(0px 0px 15px rgb(195, 11, 183));
  cursor: pointer;
}

.btn--gradient--form {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin: 0;
  margin-bottom: 20px;
  color: #fff;
  color: #cb1c1e;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.78);
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgb(203, 28, 30) 0%,
    rgb(237, 28, 127) 100%
  );
  filter: drop-shadow(0px 0px 5px rgb(237, 28, 127));
}

.btn--gradient--form:hover {
  color: #cb1c1e;
  background-color: rgba(255, 255, 255, 0.7);
  background: linear-gradient(
    90deg,
    rgb(224, 179, 15) 0%,
    rgb(237, 28, 127) 100%
  );
  filter: drop-shadow(0px 0px 10px rgb(237, 28, 127));
}

.btn--gradient--play {
  background-color: rgba(218, 10, 10, 0.85);
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  filter: drop-shadow(0px 0px 5px #c30b49);
  border: none;
  border-radius: 40px;
  padding: 0 !important;
  width: 250px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em !important;
  text-transform: uppercase;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
}

.btn--gradient--play:hover {
  background: linear-gradient(
    90deg,
    rgb(195, 11, 183) 0%,
    rgba(237, 28, 36, 1) 100%
  );
  filter: drop-shadow(0px 0px 15px rgb(195, 11, 183));
}
