.home-side-text {
  width: 55%;
  position: absolute;
  bottom: 15%;
  left: 3%;
  background-color: rgba(0, 0, 0, 0.63);
  padding: 20px;
  margin: 0;
  text-align: left !important;
  border-left: 5px solid #ed1c24;
}

.home-side-text h1 {
  text-align: left;
  font-size: 2em;
  margin: 0;
}

.home-side-text b {
  text-decoration: underline;
  font-weight: normal;
}

.home-side-text,
.home-side-text h1 i {
  font-weight: bold;
  color: #ed1c24;
}


.banner-home figure,
.block-qoute,
.content-rooms,
footer,
section {
  position: relative;
}

.amenities figure h3 {
  text-align: center;
  font-size: 3.5em;
}

.precinct-text-align,
.precinct-text-align2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.precinct-text-align-recent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
}

.precinct-text-align {
  z-index: 1;
  color: white !important;
  text-shadow: #79797a -1px 4px 5px;
  margin: auto;
}

.precinct-text-align2 {
  width: 66%;
}

.text-size-home h1 {
  font-size: 5em !important;
}


.banner-home figure,
.header-figure {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  /* float: right;
  margin-right: 3%; */
  width: 450px;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  z-index: 1;
}

.banner-text-reg {
  background-color: rgba(1, 1, 1, 0.4);
}

/* @media (max-width: 412px){
.banner figure {
    width: 100% !important;
}
.play_btn {
  display: none !important;
}
} */

.banner figure {
  border-left: 5px solid #ed1c24;
}

.banner-text-reg {
  margin-right: 0 !important;
  height: auto !important;
  top: 50% !important;
}

.banner-home img {
  top: 0;
  z-index: -1;
  object-fit: cover;
  width: 100vw;
  height: 675px;
}

.banner img {
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner {
  width: 100%;
  height: 75vh;
  max-height: 80%;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  background-color: #ed1c24;
}

.banner-contents {
  position: sticky;
  height: 675px;
  width: 100%;
  overflow: hidden;
}

.banner-text-reg h1 {
  font-size: 2em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.banner-text-reg h1 em {
  color: #ed1c24;
}

.banner-price-text-reg {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
}

.banner-price {
  position: absolute;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.banner-price h4 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 21px;
  vertical-align: super;
  font-weight: normal;
}

.banner-price h1 {
  font-size: 4em;
  text-align: left;
  font-weight: normal;
  margin: 0;
  margin-bottom: 10px;
}

.banner-price h1 i {
  font-weight: normal;
  text-decoration: none;
  color: #ed1c24;
  text-decoration: underline;
  font-weight: bold;
  text-decoration-color: #fff;
}

.banner-price h1 b {
  text-decoration: underline;
  font-weight: normal;
}

.banner-price h1 c {
  font-weight: bolder;
  color: #ed1c24;
}

.banner-price p {
  font-size: 5.8em;
  margin: 0;
  margin-bottom: 30px;
  padding-top: 8px;
  font-weight: bold;
  line-height: 30px;
}

.banner-price em {
  vertical-align: super;
  padding: 10px;
  position: initial;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  border-radius: 50px;
  font-size: 15px;
}

.block-qoute {
  background-color: white;
  background-attachment: fixed;
  background-size: cover;
  border-bottom: 5px solid #c82b29;
  border-top: 5px solid #c82b29;
}

.block-qoute h1 {
  margin: 20px 0;
  padding: 20px 0;
  text-align: left;
  font-family: sans-serif;
}

.block-qoute-text {
  /* width: 64%; */
  margin: 0 auto;
  margin-right: auto;
}

.block-qoute-text i {
  text-decoration: underline;
  font-weight: bold;
  font-style: normal;
}

.block-qoute-text em {
  font-style: normal;
  font-family: sans-serif;
}

.block-qoute-text p {
  text-align: justify;
  font-family: sans-serif;
  font-size: 1em;
  margin: 0;
  padding-bottom: 20px;
}

@keyframes pulse {
  0% {
    background-color: rgba(218, 10, 10, 0.85);
    transform: scale(1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.85);
    color: rgba(218, 10, 10, 0.85);
    transform: scale(1.05);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}

@keyframes pulseBg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}
