/* BEGIN SEARCH */

.search-submit {
  visibility: hidden;
}

.search-form {
  position: fixed;
  z-index: 9991;
}

.search-icon {
  display: block;
  position: fixed;
  right: 0;
  z-index: 99999;
  height: 64px;
  width: 40%;
  top: 0;
}

.search-icon span {
  position: inherit;
  top: 0;
  right: 0;
  padding: 20px;
  background-color: #fff;
  font-size: 1.5em;
  color: #ed1c24;
  border-left: 4px solid #ed1c24;
  cursor: pointer;
  transition: 0.5s ease all;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
}

span {
  color: #ed1c24;
}

.span-active {
  color: #414141;
}

.search-btn:hover {
  background-color: #ed1c24 !important;
  border-left: 4px solid #fff !important;
  color: #fff !important;
  transition: 0.5s ease all;
}

.search-icon input {
  float: left;
  position: relative;
  padding: 24px;
  top: 0;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-left: 4px solid #ed1c24;
  color: #ed1c24;
  width: 100%;
  transform: translateX(120%);
  transition: 0.5s ease all;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  z-index: -1;
}

/* END SEARCH */
