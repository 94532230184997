.recent-post {
  overflow: visible;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  clear: both;
  border-top: 5px solid #c00f0e;
  height: auto;
}

.recent-post h1 {
  color: #cb1c1e;
  font-size: 30px;
  padding-top: 30px;
  margin: 0;
}

.recent-post em {
  text-decoration: underline;
}

.recent-post ul {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 0;
  padding-top: 1.8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  box-sizing: border-box;
  gap: 25px;
}

.recent-post ul li {
  padding: 0;
}

.recent-post ul li,
.recent-post ul li:first-child {
  box-sizing: border-box;
  position: inherit;
  padding: 0;
  margin-top: 0;
  border-color: #000;
}

.recent-post ul li a img {
  position: relative;
  transform: scale(1) rotateZ(0deg) translate(0, 0);
  -webkit-transform: scale(1) rotateZ(0deg) translate(0, 0);
  opacity: 1;
  width: 100%;
  border-radius: 0;
}

.recent-post ul li:hover {
  filter: grayscale(0.5) blur(0px) !important;
  -ms-filter: grayscale(0.5) blur(0px) !important;
  -webkit-filter: grayscale(0.5) blur(0px) !important;
  border-radius: 100px;
  background-color: transparent;
}
