.faq-ov-post p {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 20px;
}

.faq-ov-post p::before,
blockquote p::before {
  font-size: 3em;
  margin-right: 10px;
}

.faq-ov-post ul {
  width: 95%;
}

.faq-ov-post ul li {
  width: 100% !important;
}

.faq-ov-post > ul > li > .faq-ov-info-recent figure h3 {
  font-size: 1.8em;
}

.faq-ov-post > ul > li:nth-child(3) > .faq-ov-info-recent h3,
.faq-ov-post > ul > li:nth-child(2) > .faq-ov-info-recent h3 {
  font-size: 1.8em;
}

.faq-ov-post > ul > li:nth-child(8) h3 {
  font-size: 1.8em !important;
}

.faq-ov-post {
  overflow: visible;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  clear: both;
  height: auto;
}

.faq-ov-post h1 {
  color: #cb1c1e;
  font-size: 30px;
  padding-top: 30px;
  margin: 0;
}

.faq-ov-post em {
  text-decoration: underline;
}

/* .faq-ov-post ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  height: auto;
  margin: auto;
  padding: 0;
  padding-top: 1.8%;
  padding-bottom: 1.8%;
}

.faq-ov-post ul li:first-child {
  min-height: 30%;
  max-height: 450px;
  width: 60%;
  float: left;
}

.faq-ov-post ul li,
.faq-ov-post ul li:first-child {
  display: inline-block;
  box-sizing: border-box;
  position: inherit;
  border-color: #000;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  padding: 0;
  left: 0;
  overflow: hidden;
}

.faq-ov-post ul li:first-child {
  height: 100%;
  grid-area: 1/1;
  grid-row: 1/3;
}

.faq-ov-post ul li:nth-child(2) {
  border-bottom: 6px solid #c00f0e;
  grid-area: 1/2;
}

.faq-ov-post ul li:nth-child(3) {
  grid-area: 2/2;
}

.faq-ov-post ul li:nth-child(2),
.faq-ov-post ul li:nth-child(3) {
  min-height: 30% !important;
  height: 225px !important;
  /* width: 40%;
  float: left;
} */

/* .faq-ov-post ul li a img {
  position: relative;
  transform: scale(1) rotateZ(0deg) translate(0, 0);
  -webkit-transform: scale(1) rotateZ(0deg) translate(0, 0);
  opacity: 1;
  width: 100%;
  border-radius: 0;
  object-fit: cover;
} */

.faq-ov-info-recent figure li a img:hover {
  opacity: 0.2;
  background-color: transparent;
}

.faq-ov-post > ul > li > .faq-ov-info-recent {
  height: 100%;
  margin: 0;
}

.faq-ov-post > ul > li > .faq-ov-info-recent figure {
  top: 0;
}

.faq-ov-post > ul > li:nth-child(2) > .faq-ov-info-recent h3,
.faq-ov-post > ul > li:nth-child(3) > .faq-ov-info-recent h3 {
  font-size: 2.8em;
}

.faq-ov-post > ul > li > .faq-ov-info-recent figure h3 {
  text-align: center;
  font-size: 2.2em;
  margin: 0;
  animation: pulse2 3s infinite alternate;
}

.faq-ov-post > ul > li > .faq-ov-info-recent figure h4 {
  text-align: center;
  margin: 0;
}

/* .faq-ov-post > ul > li:nth-child(4) {
  width: 100%;
  max-height: 50%;
  height: 450px;
  border-top: 6px solid #c00f0e;
  border-bottom: 6px solid #c00f0e;
  float: left;
}

.faq-ov-post > ul > li:nth-child(5),
.faq-ov-post > ul > li:nth-child(6),
.faq-ov-post > ul > li:nth-child(7) {
  width: 33.333%;
  max-height: 20%;
  height: 195px;
  float: left;
}

.faq-ov-post > ul > li:nth-child(5) h3 {
  font-size: 1.8em !important;
}

.faq-ov-post > ul > li:nth-child(8) {
  width: 100%;
  max-height: 50%;
  height: 250px;
  border-top: 6px solid #c00f0e;
}

.faq-ov-post > ul > li:nth-child(8) h3 {
  font-size: 2.6em !important;
} */

.faq-ov-info-recent {
  width: 100%;
  height: 40vh;
  margin: 40px 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  border-left: 6px solid #c00f0e;
  box-sizing: border-box;
}

.faq-ov-info-recent img {
  width: 100%;
  transition: 0.5s all ease;
}

.faq-ov-info-recent figure {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
}

.faq-ov-info-recent figure:hover {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(80, 4, 4, 0.78);
  box-sizing: border-box;
  filter: grayscale(0) blur(0px) !important;
  -ms-filter: grayscale(0) blur(0px) !important;
  -webkit-filter: grayscale(0) blur(0px) !important;
}

.faq-ov-info-recent ul {
  margin-left: 0 !important;
  width: 60% !important;
  height: 25%;
  box-sizing: border-box;
}

.faq-ov-info-recent li {
  padding: 0px 10px;
  width: 52px;
  height: 30px;
  box-sizing: border-box;
}

.faq-ov-info-recent img {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
