.input--defualt {
  background-color: rgb(203, 28, 30);
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  float: left;
  clear: both;
  box-sizing: border-box;
  margin-bottom: 20px;
}
