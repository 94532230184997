thead {
  font-weight: bold;
}
.table {
  width: 100%;
  font-family: sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.table table {
  width: 100%;
}

.table-theme th {
  color: #fff;
  font-weight: 300;
  background-color: #cb1c1e;
}

.table-theme tr td:first-of-type,
.table-theme tr td:last-of-type {
  font-size: 140%;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

.table td,
.table th {
  border: none;
}

.table th {
  text-transform: uppercase;
}

th,
td {
  border: 1px solid;
  padding: 20px;
}

.center td {
  border-bottom: 1px solid #cb1c1e !important;
}

td {
  border: none !important;
}

tr td:last-of-type {
  font-size: 140%;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
