/* BEGIN SLIDER */

.slick-arrow {
  z-index: 1;
}

.SliderBTN {
  background: linear-gradient(
    90deg,
    rgb(195, 11, 73) 0%,
    rgba(237, 28, 36, 1) 100%
  );
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  opacity: 0.8;
}

.SliderBTN:hover {
  background: linear-gradient(
    90deg,
    rgb(195, 11, 183) 0%,
    rgba(237, 28, 36, 1) 100%
  );
  filter: drop-shadow(0px 0px 10px #c30b49);
  opacity: 1;
}

.SliderBTNNext {
  right: 0;
}

.SliderBTNPrev {
  left: 0;
}

/* END SLIDER */
