.contact figure {
  top: 0% !important;
}

.contact figure h4 {
  margin: 0;
  font-size: 16px !important;
  padding-bottom: 5px;
  padding-top: 15px;
  vertical-align: super;
  font-weight: normal;
}

.contact figure h3 {
  margin: 0;
  font-size: 23px;
  padding-bottom: 5px;
  padding-top: 14px;
  vertical-align: super;
  font-weight: normal;
}

.contact figure p {
  font-size: 3.8em !important;
}

.contact figure h5 {
  font-size: 1.5em !important;
  padding-top: 13px !important;
  display: block !important;
  line-height: 1.2em !important;
}

.contact figure h6 {
  font-size: 0.8em !important;
  margin: 0;
}

.contact figure button {
  display: block !important;
}
