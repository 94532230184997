.partners {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  padding: 20px 0 !important;
  width: 100%;
  margin: 0;
}

.partners img {
  height: auto !important;
}

.partners-title {
  text-align: center !important;
}

.partners h1 {
  width: 80%;
  margin: auto;
}

.partners li {
  width: auto !important;
}
