.newsletter-form,
.dropdown-pick {
  float: right;
  position: relative;
  margin-right: 0% !important;
}

.newsletter-form input {
  background-color: rgba(255, 255, 255, 0.77);
  border: none;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0;
}

.newsletter-form form {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 2fr 1fr;
}

.newsletter-form button {
  background-color: rgba(255, 255, 255, 0.85);
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.newsletter-form button:hover {
  background-color: #d3211f;
  color: #fff;
}
