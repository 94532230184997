

.footer-wrapper img {
  width: 150px;
  float: left;
  margin-left: 4%;
  animation: pulse2 3s infinite alternate;
}

.footer-wrapper ul {
  float: left;
  padding-left: 2%;
  box-sizing: border-box;
  display: flex;
}

.footer-wrapper ul li:first-child {
  margin-right: 10px;
}

.footer-wrapper ul li {
  padding: 10px;
  position: initial;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  border-radius: 50px;
  font-size: 14px;
}

.footer-wrapper ul li a {
  color: white;
}

.footer-wrapper ul li:hover,
.footer-wrapper ul li:first-child:hover {
  background-color: transparent;
  padding: 10px;
  border: none;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(195, 11, 73, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  cursor: pointer;
  font-weight: bold;
}
