body {
  margin: 0;
  padding: 0;
  transition: 0.5s all ease;
  background-color: #ed1c24;
}

* {
  scroll-behavior: smooth;
}

h1 {
  text-align: center;
}

h1,
h2 {
  color: #fff;
  font-family: sans-serif;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: #ed1c24;
}

figure > .list-image-wraper {
  max-height: 100% !important;
}

.none {
  transform: translateY(-110%);
  transition: 0.5s all ease;
}

.list-image-wraper {
  padding: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.quick-link-icon-font span {
  font-size: 6em;
}

.quick-link-icon-font li {
  padding: 20px;
}
.list-image-wraper img {
  position: initial !important;
}

.clearfix {
  clear: both;
}

.logo-link {
  position: absolute;
  width: 220px;
  height: 64px;
  left: 70px;
  animation: pulseBg 3s infinite alternate;
}

.n-font div p:first-of-type {
  margin: 0;
}

.n-font figure p:last-of-type {
  font-size: 1.5em !important;
}

.play_btn {
  position: absolute;
  bottom: 15%;
  left: 5%;
  z-index: 1;
}

.newsletter-form-popup {
  position: absolute;
  bottom: 5%;
  right: 5%;
  background-color: #ed1c24;
  padding: 20px;
}

.no-display {
  display: none !important;
}

.th tr td:first-of-type,
.th tr td:last-of-type {
  font-size: 100% !important;
  text-align: left !important;
  color: #cb1c1e !important;
}

.w-80 {
  width: 60% !important;
}

.side-featured-images .sd-imgs {
  padding: 0;
  float: left;
}

.ct .side-featured-images .about-content-text {
  margin: 0 auto;
}

.side-featured-images .sd-imgs li {
  position: initial !important;
  width: 50% !important;
  margin: 0% !important;
}

.side-featured-images .btn-post {
  width: 100% !important;
  box-sizing: border-box;
  padding: 20px 0 !important;
  margin: 0;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 2fr 2fr;
  grid-gap: 5px;
  flex-basis: 100%;
}

.side-featured-images .btn-post li {
  color: #ffffff;
  filter: grayscale(0);
  opacity: 1;
  border: 2px solid #cb1c1e;
  background-color: #cb1c1e;
  padding: 20px !important;
  margin: 1% 0 !important;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  width: 100% !important;
  box-sizing: border-box;
}

.side-featured-images .btn-post li:hover {
  color: #cb1c1e;
}

.side-featured-images .btn-post li:nth-child(1) {
  width: 60%;
}

.side-featured-images .btn-post li:nth-child(2) {
  width: 26%;
  padding: 20px !important;
}

.side-featured-images .btn-post li:nth-child(3),
.side-featured-images .btn-post li:nth-child(4) {
  width: 43%;
}

.side-featured-images .btn-post li:nth-child(5) {
  width: 34%;
}

.side-featured-images .btn-post li:nth-child(6) {
  width: 32%;
}

.side-featured-images .btn-post li:nth-child(7) {
  width: 15.1%;
}

.side-featured-images .btn-post li:nth-child(8),
.side-featured-images .btn-post li:nth-child(9) {
  width: 43%;
}

.side-featured-images .btn-post li:nth-child(10) {
  width: 60%;
}

.side-featured-images .btn-post li:nth-child(11) {
  width: 26%;
}

.quick-link-icon-font li:hover > .quick-link-icon-font p {
  background-color: #79797a;
  box-sizing: border-box;
}

.q-l img {
  width: 100%;
} 

input,
textarea {
  color: #a72b2a;
}

.logo,
.logo-qhama {
  position: absolute;
  z-index: 99999999;
}

.translate {
  opacity: 1 !important;
  display: block !important;
  padding: 0;
}

.translateX {
  transform: translateY(0%) !important;
}

.popup-inline {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  width: auto;
  height: 100%;
}

.grid-parent {
  margin-left: -15px;
  margin-right: -15px;
}

.grid-parent:after {
  content: "";
  display: table;
  clear: both;
}

button {
  cursor: pointer;
}

.center {
  margin: 0 20% 0 20% !important;
}

.left {
  width: 450px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  /* width: auto; */
  margin: 0 !important;
}

.anime-1 {
  transition: all 0.5s ease;
}

.no-img {
  background-image: none !important;
  background-color: transparent !important;
  margin: auto !important;
  width: 80% !important;
  height: auto !important;
  padding: 5% 1% 2% 1% !important;
}

.no-img2 {
  background-image: none !important;
}

.anime-2 {
  transition: all 0.3s ease;
}

.bg-ol {
  background-color: rgb(200, 43, 41);
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  overflow: hidden;
}

.block-qoute-text button a {
  color: #fff;
}

b {
  font-weight: lighter;
}

h1 i {
  text-decoration: underline;
  font-weight: bold;
  font-style: normal;
}

h1 em {
  font-style: normal;
}

.container {
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.btn-post > li > a {
  color: #ffffff;
}

.btn-post > li:hover > a {
  color: #ed1c24;
}

.logo-qhama,
.logo {
  display: block;
  width: 220px;
  height: auto !important;
  margin: 0%;
  position: absolute;
  top: 6px;
  box-sizing: border-box;
  animation: pulseBg 3s infinite alternate;
}

ul li {
  display: block;
  padding: 23px 10px;
  color: #ed1c24;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
}

.active {
  color: #009688;
  font-weight: bold;
}

.gd {
  height: 100%;
  width: 100%;
  position: absolute;
  background: -webkit-gradient(
    linear,
    0% 0%,
    0% 101%,
    color-stop(-0.2, transparent),
    from(transparent),
    color-stop(0.6, rgba(0, 0, 0, 0.15)),
    to(rgba(0, 0, 0, 0.93))
  );
  z-index: 1;
  pointer-events: none;
}

.bg-p {
  background-position-y: 0;
  transition: 0.3s all ease;
}

.bg-st,
.bg-1,
.bg-2 {
  transition: 0.3s all ease;
}

.bg-3 {
  background-size: cover;
  background-color: #fff;
  transition: 0.3s all ease;
}

.content-rooms {
  background-attachment: fixed;
  background-size: cover;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
}

.slick-list {
  overflow: unset !important;
}

.wrapper-content-rooms {
  width: 55vw;
  margin: 0 auto;
  padding-bottom: 20px;
}

.content-room-info {
  width: 100%;
  height: 40vh;
  margin: 0;
  position: relative;
  overflow: hidden;
  border-left: 6px solid #fff;
  box-shadow: 0px 0 14px 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.content-room-info-recent a,
.content-room-info a,
.faq-ov-info-recent a {
  color: #fff;
}

.content-room-info-recent {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  border-left: 6px solid #c00f0e;
  box-sizing: border-box;
}

.content-room-info img,
.content-room-info-recent img {
  width: 100%;
  height: 100%;
  transition: 0.5s all ease;
  animation: pulse2 3s infinite alternate;
  object-fit: cover;
}

.content-room-info-recent figure {
  margin: 0;
  position: absolute;
  top: 0%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  box-sizing: border-box;
}

.content-room-info-recent figure:hover {
  margin: 0;
  padding: 25px 30px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  box-sizing: border-box;
}

.content-room-info-recent ul {
  margin-left: 0 !important;
  height: 25%;
  box-sizing: border-box;
}

.content-room-info-recent li {
  padding: 0px 10px !important;
  width: 52px !important;
  height: 30px !important;
  box-sizing: border-box;
}

.content-room-info figure {
  margin: 0;
  padding: 25px 30px;
  position: absolute;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.79);
  box-sizing: border-box;

  display: grid;
  align-content: center;
  align-items: center;
}

.content-room-info figure:hover {
  margin: 0;
  padding: 25px 30px;
  position: absolute;
  left: 0;
  height: 100%;
  width: 51%;
  background-color: rgba(1, 1, 1, 0.4);
  box-sizing: border-box;
}

.content-room-info:hover > img,
.content-room-info-recent:hover > img {
  transform: scale(1.1);
  position: initial;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.5s all ease;
}

.contact figure h6,
.content-room-info figure h3,
.content-room-info-recent figure h3,
.content-room-info-recent figure h4,
.content-room-info figure h4,
.content-room-info-recent figure h5,
.content-room-info figure h5,
.content-room-info-recent figure em,
.content-room-info figure em,
.content-room-info-recent p,
.content-room-info figure p {
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
}

.content-room-info figure h3,
.content-room-info-recent figure h3 {
  font-size: 1.2em;
  margin: 0;
  background-color: #ed1c24;
  padding: 10px;
  border-radius: 50px;
}

.content-room-info-recent figure h4 {
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
}

.content-room-info-recent figure h3 {
  background: linear-gradient(
    90deg,
    rgb(237, 161, 28) 0%,
    rgb(231, 77, 77) 100%
  );
}

.content-room-info figure h5,
.content-room-info-recent figure h5 {
  font-size: 0.8em;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 16px;
  display: none;
  transition: 0.5s all ease;
}

.content-room-info figure:hover > h5,
.content-room-info figure:hover > button,
.precinct-text-align-recent figure:hover > h5,
.precinct-text-align-recent figure:hover > button {
  display: block;
  transition: 0.5s all ease;
  margin-top: 8%;
}

.content-room-info-recent figure:hover > button,
.content-room-info-recent figure:hover > h5 {
  display: block;
  transition: 0.5s all ease;
  margin-top: 0%;
}

.content-room-info figure:hover > h5 {
  display: none;
}

.no-margin {
  margin-top: 0% !important;
}

.content-room-info figure p,
.content-room-info-recent figure p {
  font-size: 5.8em;
  margin: 0;
  padding: 25px 0;
  padding-bottom: 0;
  font-weight: bold;
  line-height: 30px;
}

.content-room-info figure h4,
.content-room-info-recent figure h4 {
  margin: 0;
  margin-top: 10px;
  padding-bottom: 5px;
  font-size: 21px;
  vertical-align: super;
  font-weight: normal;
  background-color: #ed1c24;
  border-radius: 50px;
  padding: 10px;
}

.content-room-info figure em,
.content-room-info-recent figure em {
  font-size: 25px;
  vertical-align: super;
  background-color: #ed1c24;
  background: linear-gradient(
    90deg,
    rgba(237, 28, 36, 1) 0%,
    rgb(195, 11, 73) 100%
  );
  padding: 10px;
  border-radius: 100px;
  font-size: 16px;
}

.content-room-info figure button,
.content-room-info-recent figure button {
  width: 100%;
}

.content-room-info figure button:hover,
.content-room-info-recent figure button:hover,
.newsletter-form button:hover {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.85);
}

.content-room-info figure button:hover {
  background: linear-gradient(
    90deg,
    rgb(195, 11, 73) 0%,
    rgba(237, 28, 36, 1) 100%
  );
  filter: hue-rotate(340deg);
  filter: drop-shadow(0px 0px 10px #c30b49);
}

.about-content-text {
  background-color: #fff;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  width: 70%;
  margin: 0 auto 0 40%;
  padding: 40px;
  box-sizing: border-box;
  border-left: 4px solid #cb1c1e;
}

.about-content-text li {
  position: initial !important;
  width: auto;
  height: auto !important;
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.5;
  margin-top: 0 !important;
  transition: 0.5s all ease;
}

.about-content-text li:hover {
  background: transparent;
  filter: grayscale(0);
  -ms-filter: grayscale(0);
  -webkit-filter: grayscale(0);
  opacity: 1;
  transition: 0.5s all ease;
  box-sizing: border-box;
}

.about-content-text ul {
  margin: auto;
  padding: 0;
}

.about-content-text ul h1 {
  text-align: center;
  border-bottom: 3px solid rgba(59, 59, 59, 0.42);
}

.about-content-text h1 {
  color: #cb1c1e;
  text-align: left;
  font-size: 36px;
  display: grid;
  margin-top: 0;
  margin-bottom: 20px;
}

.about-content-text p,
.faq-ov-post p {
  font-family: sans-serif;
  color: #3b3b3b;
  text-transform: uppercase;
  font-weight: bold;
}

blockquote {
  margin: 0 !important;
}

blockquote p {
  font-size: 36px;
  text-align: left !important;
  font-family: serif !important;
  font-weight: bold !important;
  font-style: oblique !important;
  color: #cb1c1e !important;
  margin: 0 !important;
}

.quote-author::before {
  content: "~";
}

.quote-author {
  text-align: center !important;
  color: #cb1c1e !important;
}

blockquote p::after {
  content: "\e978";
  font-size: 1em;
  margin-left: 10px;
  font-family: "IcoMoon-Free" !important;
}

#post-192 blockquote p::after {
  content: "";
}

/* .flex {
  display: flex !important;
} */

#post-192 .about-content-text .btn-post li {
  width: 100% !important;
  box-sizing: border-box;
  font-size: 1.5em;
}

.side-featured-images li:hover {
  background-color: transparent;
}

.side-featured-images li {
  left: 0 !important;
  top: 0;
  bottom: 0;
  padding: 0 !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.has-text-align-center {
  text-align: center !important;
  font-size: 1.6em
}

.side-featured-images li:nth-child(2) {
  position: absolute;
  margin-top: 17%;
  /* padding: 0 !important; */
  height: 50%;
  overflow: hidden;
}

.side-featured-images {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
}

.side-featured-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-rooms ul {
  position: absolute;
  width: 30%;
  height: 100%;
  left: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-rooms ul li {
  border: none;
  position: initial;
  padding: 0;
  width: 100%;
  height: 35%;
  overflow: hidden;
}

.content-rooms ul li:first-child:hover {
  transform: scale(1);
}

.content-rooms ul li img {
  position: initial;
  width: 100%;
  overflow: hidden;
}

.content-room-info .fill {
  width: 100%;
  height: 100%;
}

.content-room-info .fill:hover {
  width: 100%;
  height: 100%;
}

.wrapper-content-rooms .fill-size {
  width: 40%;
}

.content-room-info figure,
.content-room-info figure:hover {
  width: 100%;
  top: 0;
}

.content-room-info figure button,
.content-room-info-recent figure button {
  display: block;
}

.wrapper-content-rooms ul li {
  display: inline-block;
}

.img-fill img {
  height: 100%;
}

.author-copyright {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #ed1c24;
  opacity: 1;
  display: block;
}

.author-copyright p {
  text-align: center;
  color: #fff;
  font-size: 1em;
  font-family: sans-serif;
}

.author-copyright p a {
  color: #fff;
  font-weight: bold;
}

.author-copyright p a:hover {
  color: #dadada;
}

.bg-cover {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 100%;
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  position: fixed;
  overflow: hidden;
  opacity: 0.3;
  z-index: -1;
}

.bg-ol-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    0% 100%,
    0% 0%,
    color-stop(-0.2, transparent),
    from(transparent),
    color-stop(0.9, rgba(255, 0, 0, 0.2)),
    to(rgba(255, 0, 0, 0.25))
  );
}

.contact-map-container {
  width: 80%;
  height: 84%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.contact-map-container aside {
  width: 25%;
  height: 100%;
  background-color: #2dbfcc;
  border-right: 6px solid #c00f0e;
  opacity: 1;
}

.contact-map-container figure {
  width: 100%;
  height: 100%;
  margin: 0;
  background: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    color-stop(-0.2, transparent),
    from(transparent),
    color-stop(0.9, rgba(255, 0, 0, 0.98)),
    to(rgb(255, 0, 0))
  );
}

.contact-map-container figure:hover {
  background-color: rgba(255, 0, 0, 0.63);
  color: #101010 !important;
}

@media only screen and (max-width: 320px) and (max-height: 568px) {
  .banner-price h1 {
    font-size: 2.5em !important;
  }

  header ul li,
  .banner-home ul li {
    font-size: 0.8em !important;
  }

  .banner-price {
    transform: translate(-50%, -45%) !important;
    -webkit-transform: translate(-50%, -45%) !important;
  }

  .list-image-wraper {
    left: 0;
  }

  nav {
    /* width: 175px !important; */
    height: 50px;
  }

  .nav-trigger {
    font-size: 1.1em !important;
  }

  .logo-qhama,
  .logo {
    width: 80px !important;
    top: 15px !important;
  }

  ul li {
    padding: 10px 10px !important;
  }

  .author-copyright p {
    font-size: 0.8em !important;
  }

  .logo-link {
    height: auto !important;
  }
}

@media only screen and (max-width: 375px) and (max-width: 667px) {
  .play_btn {
    bottom: 5%;
    left: 18% !important;
  }

  .banner-price h1 {
    font-size: 3em !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logo-qhama,
  .logo {
    width: 125px !important;
    top: 20px !important;
  }
}

@media only screen and (max-width: 411px) and (max-width: 823px) {
  .quick-link-icon-font span {
    font-size: 4em !important;
  }

  .play_btn {
    bottom: 5% !important;
    left: 20% !important;
    display: none !important;
  }

  .faq-ov-post h1 {
    padding: 30px 0 !important;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 2.8em !important;
  }

  .logo-qhama,
  .logo {
    width: 122px !important;
    top: 15px !important;
  }

  .logo-link {
    width: 122px !important;
    left: 20px !important;
  }

  .nav-trigger {
    font-size: 1.4em !important;
  }

  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    height: 100% !important;
  }

  .side-featured-images .btn-post li {
    width: 100% !important;
    box-sizing: border-box;
  }

  header ul li:hover > .sub-menu {
    display: block !important;
    width: 75% !important;
    height: auto !important;
    left: 0 !important;
    top: 0 !important;
    opacity: 1 !important;
    position: absolute !important;
    transform: translate(245px, 0) !important;
    overflow: hidden !important;
  }

  .popup-inline {
    background: transparent;
  }

  .precinct-text-align2 {
    width: 64% !important;
  }

  .wp-post-image {
    min-width: 200% !important;
    height: 100% !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-45%, -50%) !important;
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  .live-form button,
  .live-form-2 button {
    animation: none !important;
  }

  .home-side-text {
    display: none !important;
  }

  .fill-height {
    height: 100% !important;
    width: 235px !important;
  }

  .map-close {
    top: 0%;
  }

  .q-l ul {
    width: 100%;
  }

  .q-l li {
    width: auto;
    height: auto;
  }

  .q-l li p {
    display: none;
  }

  .mfp-wrap {
    position: relative !important;
  }

  .bg-5,
  .bg-ol {
    background: transparent;
  }

  .mfp-bg {
    opacity: 0.999 !important;
    filter: alpha(opacity=90) !important;
    z-index: 999999 !important;
  }

  .margin-or {
    margin-top: 40% !important;
  }

  .table-theme td,
  .table th {
    display: block;
    width: auto;
  }

  .logo {
    display: block;
    background-size: cover;
    float: left;
    padding: 0;
  }

  ul {
    font-size: 15px;
  }

  .recent-post ul li,
  .recent-post ul li:first-child {
    display: inline-block;
    width: 100% !important;
    padding: 10px;
  }

  .content-room-info-recent figure h5 {
    display: block;
  }

  .banner-home {
    background-position: right;
  }

  .banner figure {
    margin: 0;
    width: 100%;
    box-sizing: border-box !important;
  }

  .banner-home figure {
    margin: 0;
    width: 100%;
  }

  .banner-text-reg h1 {
    padding: 0;
  }

  .about-content-text h1 {
    font-size: 20px;
  }

  .text-size-home h1 {
    font-size: 4.5em;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0;
    font-size: 20px;
  }

  .banner-price p {
    font-size: 3.8em;
  }

  .banner-price h1 {
    font-size: 3em !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto;
  }

  .banner-price button {
    padding: 15px 80px;
    margin: 20px auto;
    width: 90%;
  }

  header ul li,
  .banner-home ul li {
    position: relative;
    display: block;
    text-align: right;
    margin: 0;
    left: 0;
    font-size: 1em;
  }

  .block-qoute-text {
    width: 95%;
    margin: auto;
  }

  .wrapper-content-rooms {
    width: 95%;
  }

  .contact figure h5 {
    font-size: 1em !important;
  }

  .contact figure p {
    font-size: 1.8em !important;
  }

  .content-room-info {
    height: 345px;
  }

  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100%;
    top: 0%;
  }

  .content-room-info figure h5 {
    display: block;
    padding-top: 30px;
  }

  .content-room-info-recent figure h5 {
    padding-top: 20px;
  }

  .content-room-info figure button {
    font-size: 0.9em;
    display: block;
    margin-top: 20px !important;
  }

  .content-room-info figure h3 {
    font-size: 1.5em;
    margin: 0;
  }

  .bg-3 {
    background-attachment: fixed;
  }

  .about-content-text {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 40px 20px 40px 20px !important;
    position: initial;
    float: none;
  }

  .content-room-info-recent {
    width: 100%;
    margin: 10px 0;
  }

  .content-room-info-recent figure,
  .content-room-info-recent figure:hover {
    top: 0%;
    width: 100% !important;
  }

  .fill-size figure {
    padding: 5% 30px;
  }

  .fill-size {
    height: 260px;
    width: 100% !important;
  }

  .content-room-info-recent figure h3 {
    font-size: 1.6em;
    margin: 0;
  }

  .content-room-info-recent figure button {
    margin-top: 3%;
    display: block;
  }

  .mini-gallery {
    height: auto;
  }

  .mini-gallery li {
    height: 100px;
  }

  .live-form {
    display: block;
    width: 100%;
    padding: 75px 20px;
  }
  .dropdown-pick {
    float: right;
  }

  .contact-map-container aside {
    display: none;
  }

  .map-iframe {
    width: 100% !important;
  }

  .line p {
    margin: 0px 60% 0px 0px !important;
    background-color: transparent;
  }

  footer {
    height: auto !important;
  }

  .footer-wrapper ul {
    text-align: center !important;
    float: none !important;
    padding-left: 0% !important;
    width: 100% !important;
  }

  .footer-wrapper ul li {
    display: block !important;
  }

  .footer-wrapper img {
    display: none !important;
  }

  .newsletter-form {
    margin-right: auto !important;
  }

  .newsletter-form input::placeholder {
    color: rgb(245, 21, 21);
  }

  .newsletter-form,
  .dropdown-pick {
    float: none !important;
    margin: 0 !important;
  }

  .newsletter-form input {
    width: 100% !important;
    padding: 10px 20px 10px 20px !important;
  }

  .newsletter-form button {
    width: 100% !important;
    margin: 10px 0px !important;
    left: 0px !important;
    position: relative !important;
    float: none !important;
  }

  .down-arrow {
    background-image: none;
  }

  .content-room-info img,
  .content-room-info-recent img {
    height: 100% !important;
  }

  .side-featured-images ul {
    display: none;
  }

  .btn-post {
    display: block !important;
    padding: 0 !important;
    display: flex;
    min-width: fit-content;
    flex-wrap: wrap;
  }

  .partners {
    display: flex !important;
  }
}

@media only screen and (max-width: 414px) and (max-height: 736px) {
  .quick-link-icon-font span {
    font-size: 4em !important;
  }

  .content-room-info figure em {
    font-size: 15px !important;
  }

  .content-room-info figure h4 {
    margin-bottom: 0 !important;
    font-size: 20px !important;
  }

  .play_btn {
    bottom: 5% !important;
    left: 20% !important;
    display: none !important;
  }

  .faq-ov-post h1 {
    padding: 30px 0 !important;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 2.8em !important;
  }

  .logo-qhama,
  .logo {
    width: 122px !important;
    top: 15px !important;
  }

  .logo-link {
    width: 122px !important;
    left: 20px !important;
  }

  .nav-trigger {
    font-size: 1.4em !important;
  }

  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    height: 100% !important;
  }

  .side-featured-images .btn-post li {
    width: 100% !important;
    box-sizing: border-box;
  }

  header ul li:hover > .sub-menu {
    display: block !important;
    width: 75% !important;
    height: auto;
    left: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    transform: translate(245px, 0) !important;
    overflow: hidden;
  }

  .popup-inline {
    background: transparent;
  }

  /* .precinct-text-align2 {
    width: 64% !important;
  } */

  .wp-post-image {
    min-width: 200%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%) !important;
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
  }

  .live-form button,
  .live-form-2 button {
    animation: none !important;
  }

  .home-side-text {
    display: none !important;
  }

  .fill-height {
    height: 100% !important;
    width: 235px !important;
  }

  .map-close {
    top: 0% !important;
  }

  .q-l ul {
    width: 100% !important;
  }

  .q-l li {
    width: auto !important;
    height: auto !important;
  }

  .q-l li p {
    display: none !important;
  }

  .mfp-wrap {
    position: relative !important;
  }

  .bg-5,
  .bg-ol {
    background: transparent;
  }

  .mfp-bg {
    opacity: 0.999 !important;
    filter: alpha(opacity=90) !important;
    z-index: 999999 !important;
  }

  .margin-or {
    margin-top: 40% !important;
  }

  .table-theme td,
  .table th {
    display: block;
    width: auto;
  }

  .logo {
    display: block;
    background-size: cover;
    float: left;
    padding: 0;
  }

  ul {
    font-size: 15px !important;
  }

  .recent-post ul li,
  .recent-post ul li:first-child {
    display: inline-block !important;
    width: 100% !important;
    padding: 10px !important;
  }

  .content-room-info-recent figure h5 {
    display: block !important;
  }

  .banner-home {
    background-position: right;
  }

  .banner figure {
    margin: 0 !important;
    width: 100% !important;
    box-sizing: border-box;
  }

  .banner-home figure {
    margin: 0 !important;
    width: 100% !important;
  }

  .banner-text-reg h1 {
    padding: 0 !important;
  }

  .about-content-text h1 {
    font-size: 20px !important;
  }

  .text-size-home h1 {
    font-size: 4.5em !important;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    height: 495px !important;
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0 !important;
    font-size: 20px !important;
  }

  .banner-price p {
    font-size: 3.8em !important;
  }

  .banner-price h1 {
    font-size: 2.5em !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto !important;
  }

  .banner-price button {
    padding: 15px 80px !important;
    margin: 20px auto !important;
    width: 90% !important;
  }

  header ul li,
  .banner-home ul li {
    position: relative !important;
    display: block !important;
    /* text-align: right !important; */
    margin: 0 !important;
    left: 0 !important;
    font-size: 1em !important;
    font-family: serif !important;
  }

  .block-qoute-text {
    width: 95% !important;
    margin: auto !important;
  }
  .wrapper-content-rooms {
    width: 95% !important;
  }

  .contact figure h5 {
    font-size: 1em !important;
  }

  .contact figure p {
    font-size: 1.8em !important;
  }

  .content-room-info {
    height: 220px;
  }

  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100% !important;
    top: 0% !important;
  }

  .content-room-info figure h5 {
    display: block !important;
    padding-top: 30px !important;
  }

  .content-room-info-recent figure h5 {
    padding-top: 20px !important;
  }

  .content-room-info figure button {
    font-size: 0.9em !important;
    display: block !important;
    margin-top: 20px !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .content-room-info figure h3 {
    font-size: 1.5em !important;
    margin: 0 !important;
  }

  blockquote p {
    font-size: 1.444em;
  }

  .content-room-info figure em,
  .content-room-info-recent figure em {
    font-size: 15px;
  }

  .bg-3 {
    background-attachment: fixed;
  }

  .about-content-text {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 40px 20px 40px 20px !important;
    position: initial !important;
    float: none !important;
  }

  .content-room-info-recent {
    width: 100% !important;
    margin: 10px 0 !important;
  }

  .content-room-info-recent figure,
  .content-room-info-recent figure:hover {
    top: 0% !important;
    width: 100% !important;
  }

  .fill-size figure {
    padding: 5% 30px !important;
  }

  .fill-size {
    height: 260px !important;
    width: 100% !important;
  }

  .content-room-info-recent figure h3 {
    font-size: 1.3em !important;
    margin: 0 !important;
  }

  .content-room-info-recent figure button {
    margin-top: 3% !important;
    display: block !important;
  }

  .mini-gallery {
    height: auto !important;
  }

  .mini-gallery li {
    height: 100px !important;
  }

  .live-form {
    display: block !important;
    width: 100% !important;
    padding: 75px 20px !important;
  }

  .dropdown-pick {
    float: right !important;
  }

  .contact-map-container aside {
    display: none !important;
  }

  .map-iframe {
    width: 100% !important;
  }

  .line p {
    margin: 0px 60% 0px 0px !important;
    background-color: transparent !important;
  }

  .faq-ov-post ul {
    width: 95% !important;
  }

  /* .faq-ov-post ul li {
    height: 30% !important;
    width: 100% !important;
  } */

  .faq-ov-post > ul > li > .faq-ov-info-recent figure h3 {
    font-size: 1.8em !important;
  }

  .faq-ov-post > ul > li:nth-child(3) > .faq-ov-info-recent h3,
  .faq-ov-post > ul > li:nth-child(2) > .faq-ov-info-recent h3 {
    font-size: 1.8em !important;
  }

  .faq-ov-post > ul > li:nth-child(8) h3 {
    font-size: 1.8em !important;
  }

  footer {
    height: auto !important;
  }

  .footer-wrapper ul {
    text-align: center !important;
    float: none !important;
    padding-left: 0% !important;
    width: 100% !important;
  }

  .footer-wrapper ul li {
    display: block !important;
  }

  .footer-wrapper img {
    display: none !important;
  }

  .newsletter-form {
    margin-right: auto !important;
  }

  .newsletter-form,
  .dropdown-pick {
    float: none !important;
    margin: 0 !important;
  }

  .newsletter-form input {
    width: 100% !important;
    padding: 10px 20px 10px 20px !important;
  }

  .newsletter-form button {
    width: 100% !important;
    margin: 10px 0px !important;
    left: 0px !important;
    position: relative !important;
    float: none !important;
  }

  .down-arrow {
    background-image: none !important;
  }

  .content-room-info img,
  .content-room-info-recent img {
    height: 100% !important;
  }

  .side-featured-images ul {
    display: none !important;
  }

  .btn-post {
    display: block !important;
    padding: 0 !important;
  }

  .partners {
    display: flex !important;
  }
}

@media only screen and (max-width: 768px) and (max-height: 1024px) {
  .quick-link-icon-font span {
    font-size: 4em;
  }

  .faq-ov-post ul li:first-child {
    width: 60%;
  }

  .play_btn {
    bottom: 5%;
    left: 4%;
  }

  .faq-ov-post h1 {
    padding: 30px 0;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 2.8em;
  }

  .logo-qhama,
  .logo {
    width: 185px;
    top: 10px;
  }

  .logo-link {
    width: 225px;
    left: 30px;
  }

  .nav-trigger {
    font-size: 1.4em;
  }

  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    height: 100% !important;
  }

  .side-featured-images .btn-post li {
    width: 100% !important;
    box-sizing: border-box;
  }

  header ul li:hover > .sub-menu {
    display: block;
    width: 75% !important;
    height: auto;
    left: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    transform: translate(394px, 0);
    overflow: hidden;
  }

  .popup-inline {
    background: transparent;
  }

  /* .precinct-text-align2 {
    width: 64%;
  } */

  .wp-post-image {
    min-width: 200%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  .live-form button,
  .live-form-2 button {
    animation: none;
  }

  .home-side-text {
    display: none;
  }

  .fill-height {
    height: 100%;
    width: 385px;
  }

  .map-close {
    top: 0%;
  }

  .q-l ul {
    width: 100%;
  }

  .q-l li {
    width: auto;
    height: auto;
  }

  .q-l li p {
    display: none;
  }

  .mfp-wrap {
    position: relative !important;
  }

  .bg-5,
  .bg-ol {
    background: transparent;
  }

  .mfp-bg {
    opacity: 0.999 !important;
    filter: alpha(opacity=90) !important;
    z-index: 999999 !important;
  }

  .margin-or {
    margin-top: 40% !important;
  }

  .table-theme td,
  .table th {
    display: block;
    width: auto;
  }

  .logo {
    display: block;
    background-size: cover;
    float: left;
    padding: 0;
  }

  ul {
    font-size: 15px;
  }

  .recent-post ul li,
  .recent-post ul li:first-child {
    display: inline-block;
    width: 33%;
    padding: 10px;
  }

  .content-room-info-recent figure h5 {
    display: block;
  }

  .banner-home {
    background-position: right;
  }

  .banner figure {
    margin: 0;
    box-sizing: border-box;
  }

  .banner-home figure {
    margin: 0;
  }

  .banner-text-reg h1 {
    padding: 0;
  }

  .about-content-text h1 {
    font-size: 20px;
  }

  .text-size-home h1 {
    font-size: 4.5em;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    height: 60%;
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0;
    font-size: 20px;
  }

  .banner-price p {
    font-size: 3.8em;
  }

  .banner-price h1 {
    font-size: 3em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto;
  }

  .banner-price button {
    padding: 15px 80px;
    margin: 20px auto;
    width: 90%;
  }

  header ul li,
  .banner-home ul li {
    position: relative;
    display: block;
    text-align: right;
    margin: 0;
    left: 0;
    font-size: 1em;
  }

  .block-qoute-text {
    width: 95%;
    margin: auto;
  }

  .wrapper-content-rooms {
    width: 95%;
  }

  .contact figure h5 {
    font-size: 1em !important;
  }

  .contact figure p {
    font-size: 1.8em !important;
  }

  .content-room-info {
    height: 345px;
  }

  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100%;
    top: 0%;
  }

  .content-room-info figure h5 {
    display: block;
    padding-top: 30px;
  }

  .content-room-info-recent figure h5 {
    padding-top: 20px;
  }

  .content-room-info figure button {
    font-size: 0.9em;
    display: block;
    margin-top: 20px !important;
  }

  .content-room-info figure h3 {
    font-size: 1.5em;
    margin: 0;
  }

  .bg-3 {
    background-attachment: fixed;
  }

  .about-content-text {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 40px 20px 40px 20px !important;
    position: initial;
    float: none;
  }

  .content-room-info-recent {
    width: 100%;
    margin: 10px 0;
  }

  .content-room-info-recent figure,
  .content-room-info-recent figure:hover {
    top: 0%;
    width: 100% !important;
  }

  .fill-size figure {
    padding: 5% 30px;
  }

  .fill-size {
    height: 260px;
    width: 100% !important;
  }

  .content-room-info-recent figure h3 {
    font-size: 1.6em;
    margin: 0;
  }

  .content-room-info-recent figure button {
    margin-top: 3%;
    display: block;
  }

  .mini-gallery {
    height: auto;
  }

  .mini-gallery li {
    height: 100px;
  }

  .live-form {
    display: block;
    width: 100%;
    padding: 75px 20px;
  }

  .dropdown-pick {
    float: right;
  }

  .contact-map-container aside {
    display: none;
  }

  .map-iframe {
    width: 100% !important;
  }

  .line p {
    margin: 0px 60% 0px 0px !important;
    background-color: transparent;
  }

  .faq-ov-post ul {
    width: 95%;
  }

  .faq-ov-post ul li {
    width: 100% !important;
  }

  .faq-ov-post > ul > li > .faq-ov-info-recent figure h3 {
    font-size: 1.8em;
  }

  .faq-ov-post > ul > li:nth-child(3) > .faq-ov-info-recent h3,
  .faq-ov-post > ul > li:nth-child(2) > .faq-ov-info-recent h3 {
    font-size: 1.8em;
  }

  .faq-ov-post > ul > li:nth-child(8) h3 {
    font-size: 1.8em !important;
  }

  footer {
    height: auto !important;
  }

  .footer-wrapper ul {
    text-align: center !important;
    float: none !important;
    padding-left: 0% !important;
    width: 100% !important;
  }

  .footer-wrapper ul li {
    display: inline-block;
  }

  .footer-wrapper img {
    display: none !important;
  }

  .newsletter-form {
    margin-right: auto !important;
  }

  .newsletter-form,
  .dropdown-pick {
    float: none !important;
    margin: 0 !important;
  }

  .newsletter-form input {
    width: 100% !important;
    padding: 10px 20px 10px 20px !important;
  }

  .newsletter-form button {
    width: 100% !important;
    margin: 10px 0px !important;
    left: 0px !important;
    position: relative !important;
    float: none !important;
  }

  .down-arrow {
    background-image: none;
  }

  .content-room-info img,
  .content-room-info-recent img {
    height: 100% !important;
  }

  .side-featured-images ul {
    display: none;
  }

  .btn-post {
    display: block !important;
    padding: 0 !important;
  }

  .partners {
    display: flex !important;
  }
}

@media only screen and (max-width: 950px) and (max-width: 1280px) {
  .content-room-info figure h3,
  .content-room-info-recent figure h3 {
    font-size: 1.4em;
    margin: 0;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 4.8em;
  }

  .banner img,
  .banner-home img {
    width: 100%;
  }

  .content-room-info figure:hover {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) and (max-height: 1366px) {
  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100%;
    top: 0%;
  }

  .content-room-info figure button,
  .content-room-info-recent figure button {
    display: block;
  }

  header ul li:hover > .sub-menu {
    display: block;
    width: 75% !important;
    height: auto;
    left: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    transform: translate(534px, 0);
    overflow: hidden;
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  .banner-home {
    background-position: right;
  }

  .banner-text-reg h1 {
    padding: 0;
  }

  .about-content-text h1 {
    font-size: 20px;
  }

  .text-size-home h1 {
    font-size: 4.5em;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0;
    font-size: 20px;
  }

  .banner-price p {
    font-size: 3.8em;
  }

  .banner-price h1 {
    font-size: 4em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto;
  }

  .banner-price button {
    padding: 15px 80px;
    margin: 20px auto;
    width: 90%;
  }

  header ul li,
  .banner-home ul li {
    position: relative;
    display: block;
    text-align: center;
    margin: 0;
    left: 0;
    font-size: 1em;
  }

  .content-room-info figure h3,
  .content-room-info-recent figure h3 {
    font-size: 2.8em;
    margin: 0;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 3.8em;
  }

  .about-content-text {
    width: 85%;
  }

  footer {
    zoom: 86%;
  }
}

@media only screen and (max-width: 1366px) and (max-height: 1024px) {
  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100%;
    top: 0%;
  }

  ul li,
  .sub-menu li,
  .sub-menu li:hover {
    padding: 10px 10px;
  }

  .content-room-info figure button {
    display: block;
    margin-top: 55px;
  }

  .content-room-info-recent figure button {
    display: none;
  }

  .content-room-info figure h4 {
    margin-bottom: 25px;
    font-size: 35px;
  }

  header ul li:hover > .sub-menu {
    display: block;
    width: 75% !important;
    height: auto;
    left: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    transform: translate(534px, 0);
    overflow: hidden;
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  .banner-home {
    background-position: right;
  }

  .about-content-text h1 {
    font-size: 20px;
  }

  .text-size-home h1 {
    font-size: 4.5em;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0;
    font-size: 20px;
  }

  .banner-price p {
    font-size: 3.8em;
  }

  .banner-price h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto;
  }

  .banner-price button {
    padding: 15px 80px;
    margin: 20px auto;
    width: 90%;
  }

  header ul li,
  .banner-home ul li {
    position: relative;
    display: block;
    text-align: center;
    margin: 0;
    left: 0;
    font-size: 1em;
  }

  .content-room-info figure h3 {
    font-size: 2.2em;
    margin: 0;
  }

  .content-room-info-recent figure h3 {
    font-size: 1.2em;
  }

  .content-room-info-recent figure p {
    font-size: 3.8em;
  }

  .content-room-info figure p {
    font-size: 7.8em;
  }

  .content-room-info figure em {
    vertical-align: super;
  }

  .about-content-text {
    width: 85%;
  }

  footer {
    zoom: 80%;
  }
}

@media only screen and (max-width: 1440px) and (max-height: 900px) {
  .content-room-info figure,
  .content-room-info figure:hover {
    width: 100%;
    top: 0%;
  }

  .content-room-info figure button,
  .content-room-info-recent figure button {
    display: block;
  }

  header ul li:hover > .sub-menu {
    display: block;
    width: 75% !important;
    height: auto;
    left: 0;
    top: 0;
    opacity: 1;
    position: absolute;
    transform: translate(534px, 0);
    overflow: hidden;
  }

  header ul,
  .banner-home ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 0;
  }

  .banner-home {
    background-position: right;
  }

  .banner-text-reg h1 {
    padding: 0;
  }

  .about-content-text h1 {
    font-size: 20px;
  }

  .text-size-home h1 {
    font-size: 4.5em;
  }

  .banner,
  .banner-home,
  .cb-slideshow,
  .cb-slideshow:after,
  .cb1-slideshow,
  .cb1-slideshow:after,
  .cb2-slideshow,
  .cb2-slideshow:after {
    overflow: hidden;
  }

  .banner-price h4 {
    padding-bottom: 0;
    font-size: 20px;
  }

  .banner-price p {
    font-size: 3.8em;
  }

  .banner-price h1 {
    font-size: 4em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .banner-price {
    margin: auto;
  }

  .banner-price button {
    padding: 15px 80px;
    margin: 20px auto;
    width: 90%;
  }

  header ul li,
  .banner-home ul li {
    position: relative;
    display: block;
    text-align: center;
    margin: 0;
    left: 0;
    font-size: 1em;
  }

  .content-room-info figure h3,
  .content-room-info-recent figure h3 {
    font-size: 1em;
    margin: 0;
  }

  .content-room-info figure p,
  .content-room-info-recent figure p {
    font-size: 4.8em;
  }

  .about-content-text {
    width: 85%;
  }

  footer {
    zoom: 86%;
  }
}

.navOW .drawer {
  position: fixed;
  background-attachment: fixed;
  background-size: contain;
  width: 30%;
  height: 100vh;
  background-color: #eaeaea;
  z-index: 101;
  transform: translateX(-101%);
  display: grid;
  align-items: center;
  align-content: center;
}

.navOW .drawerOn {
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  transform: translateX(0);
}

.ContentWrapperX {
  transform: translateX(30%);
}

.TransitionON {
  transform: translateX(0%) !important;
  transition-delay: 0.5ms;
}

.searchResults {
  margin-top: 64px;
  position: fixed;
  right: 0px;
  z-index: 1;
  width: 40%;
  height: 100vh;
  overflow: scroll;
  top: 0px;
  bottom: 0px;
  background-color: rgb(237, 28, 36);
  border-left: 4px solid rgb(255, 255, 255);
  transition: 0.6s ease all;
  transition-delay: 0.5ms;
  transform: translateX(100%);
  box-sizing: border-box;
}

.fd {
  transition: 0.6s ease all;
  transition-delay: 0.4s;
}

.SearchQuery div {
  margin-bottom: 2px;
}

.modalBG {
  visibility: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 28, 36, 0.89);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 0;
  transition: 0.6s ease all;
  cursor: pointer;
}

.FadeON {
  visibility: visible;
  opacity: 1;
}

::placeholder {
  color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #fff;
}

::-webkit-scrollbar-track {
  background: #ed1c24;
}

:root {
  --animate-duration: 0.3s;
}

.wrapper-content-rooms .slick-slide {
  filter: grayscale(1);
  transition: 0.5s ease-out all;
}

.wrapper-content-rooms .slick-active {
  filter: grayscale(0);
  transition: 0.5s ease-in all;
}

.modalContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  cursor: default;
}

.modalWrapper {
  position: relative;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
}

.modalWrapper h1 {
  color: #ed1c24;
  padding: 20px;
  margin: 0;
}

.modalClose {
  padding: 10px;
  background-color: #414141;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  cursor: pointer;
  z-index: 1;
}

.modalClose:hover {
  background-color: #79797a;
  color: #fff;
}

.downloadList {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
  margin-bottom: 5px;
}

.downloadList:last-of-type {
  margin-bottom: 0;
}

.downloadList button {
  margin: 0;
  border-radius: 0;
}

.downloadList p {
  margin: 0;
  background-color: #ed1c24;
  line-height: 30px;
  color: white;
  font-size: 15px;
  padding: 10px;
}

.modalCloseIcon {
  margin-left: 5px;
}

.signup-modal {
  position: fixed;
  background-color: #06040499;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}

.signup-modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.content-wrapper-signup-modal {
  position: relative;
  width: 70%;
  margin: auto;
  background-color: #ffffff;
  overflow: auto;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 20px 0px #00000030;
}

.content-signup-modal {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 25px;
}

.content-signup-modal > section:nth-child(1) {
  border-right: 4px solid #414141;
}

.content-signup-modal > section:nth-child(2) {
  display: grid;
  align-content: center;
  padding: 20px 0;
  padding-right: 25px;
}

.content-signup-modal > section img,
.content-signup-modal > section video {
  all: unset;
  position: relative;
  top: 0;
  width: 95%;
  height: 90%;
  display: block;
  object-fit: cover;
  border: none !important;
  box-shadow: 0 0 20px 0px #00000030;
  margin: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}