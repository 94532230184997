.map-img {
  background-size: cover;
  background-position-x: -50px;
  width: 100%;
  height: 50%;
}

.map-img h2,
.map-img-2 h2 {
  padding: 10% 20px 0px 20px;
  margin: 0;
  font-size: 2.2em;
  position: relative;
}

.map-img h1,
.map-img-2 h1 {
  text-align: left;
  padding: 0px 20px 0px 20px;
  margin-top: 40%;
  margin-bottom: 0;
  font-size: 3em;
  font-weight: lighter;
}

.map-img em,
.map-img-2 em {
  text-decoration: underline;
  font-weight: bold;
}

.map-img-2 {
  background-size: cover;
  background-position-x: -50px;
  width: 100%;
  height: 50%;
}

.map-close {
  position: absolute;
  right: 0;
  top: 0%;
  font-family: sans-serif;
  font-weight: bolder;
  color: #a72b2a;
  box-sizing: border-box;
  width: 8%;
  z-index: 9999;
}

.line {
  border-bottom: 5px solid;
}

.line p {
  background-color: white;
  padding: 20px;
  margin: 0;
  margin: 0px 84% 0px 0px;
}

.map-iframe {
  width: 100%;
  height: 100%;
  border: 8px solid rgb(203, 28, 30);
  box-sizing: border-box;
}

.map-iframe img {
  width: 100%;
  height: 100%;
}
