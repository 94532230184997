/* BEGIN 404 PAGES */
.btn404 {
  font-family: sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  padding: 2%;
  border: 4px solid #ff9800;
  color: #ff9800;
  animation: pulseBg 3s infinite alternate;
}

.btn404:hover,
.btn404:active {
  background-color: rgba(237, 28, 36, 0.16);
  border: 4px solid #ed1c24;
  color: #ed1c24;
}

.error404 {
  width: 60%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.fetchErr {
  width: 100%;
  height: 100vh;
  object-fit: contain;
  display: block;
}

/* END 404 PAGES */
