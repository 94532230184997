/*  BEGIN BACK TO TOP */

.back-to-top {
  font-size: 2em;
  position: fixed;
  right: -15%;
  bottom: 20%;
  padding: 15px;
  background-color: rgba(203, 28, 30, 0.4);
  color: #fff;
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.58);
  z-index: 100;
  cursor: pointer;
  transition: 0.5s ease all;
}

.back-to-top > a {
  font-weight: bold !important;
  color: #3c3c42 !important;
}

.back-to-top:hover {
  background-color: rgba(255, 79, 0, 0.4);
  color: #fff;
  transition: 0.5s ease all;
}

.back-to-top:hover > a {
  color: #ecac22 !important;
}

.back-to-top-on {
  right: 0;
  transition: 0.5s ease all;
}

.BackToTopBTN {
  display: flex;
  align-items: center;display: flex;
  align-content: center;
  position: fixed !important;
  width: 50px;
  height: 50px;
  bottom: 67px;
  top: unset !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-30%) scale(0.8) !important;
}

.BackToTopBTN svg {
  margin: auto;
}

.BackToTopBTNoff {
  display: flex;
  align-items: center;display: flex;
  position: fixed !important;
  bottom: 67px;
  top: unset !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(270%) scale(1.4) !important;
}

/* END BACK TO TOP */
