.live-form {
  padding: 2% 0;
  width: 80%;
  float: left;
  box-sizing: border-box;
}

.live-form-2 h1 {
  color: #cb1c1e !important;
}

.live-form h1,
.live-form-2 h1 {
  text-align: left;
  color: #ffffff;
  padding: 20px;
  font-size: 3em;
  margin: 0;
}

.live-form em,
.live-form-2 em {
  font-weight: lighter;
  color: #fff !important;
}

.live-form-2 textarea,
.live-form-2 input,
.live-form-2 button {
  background-color: rgb(203, 28, 30) !important;
  color: #fff !important;
}

select {
  color: #79797a !important;
}

.live-form input,
.live-form-2 input,
select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  float: left;
  clear: both;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.live-form input,
select {
  background-color: rgb(203, 28, 30) !important;
  color: #fff !important;
}

.live-form-2 input {
  background-color: rgb(203, 28, 30) !important;
}

.live-form select {
  background-color: rgba(255, 255, 255, 0.78) !important;
}

.live-form-2 select {
  background-color: rgb(203, 28, 30) !important;
  color: #fff !important;
}

.live-form-2 input::placeholder,
.live-form-2 textarea::placeholder {
  color: #fff;
  font-weight: bold;
}

.search-icon input::placeholder,
.newsletter-form input::placeholder {
  color: #ed1c24;
  font-weight: bold;
}

input,
textarea,
select {
  outline: none;
}

.live-form textarea,
.live-form-2 textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  height: 300px;
  padding: 20px;
  border: none;
  border-radius: 20px;
  clear: both;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.78);
  margin-bottom: 20px;
}

.live-form button,
.live-form-2 button {
  width: 100%;
}

.sign-up-each {
  width: 50%;
  float: left;
}

.field-block {
  padding: 10px 0;
}

.label {
  font-weight: 300;
  text-align: center;
  padding: 0 0 10px 0;
  display: block;
  position: relative;
}

.label-required:after {
  content: "*";
  color: #cf5658;
  margin: 0 0 0 5px;
  font-size: 30px;
  line-height: 0;
  position: relative;
  top: 12px;
}

.input-text {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid #bbb;
  transition: all 0.2s linear;
}

input:placeholder {
  color: #fff;
}

/* END LIVE FORM */

.subscribed {
  background: linear-gradient(90deg, #ed1cd3 0%, #7c0bc3 100%) !important;
}

.memberExists {
  background: linear-gradient(90deg, #b92c29 0%, #7d0300 100%) !important;
}

.pending {
  background: linear-gradient(90deg, #ed9e1e 0%, #e8504c 100%) !important;
}

.failed {
  background: linear-gradient(90deg, #848484 0%, #353535 100%) !important;
}

.subscribed:hover {
  background-color: #673ab7 !important;
}

.memberExists {
  background-color: #e8504c !important;
}

.pending {
  background-color: #7d0300 !important;
}

.failed {
  background-color: #e8504c !important;
}
